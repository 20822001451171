<template>
  <section id="recent-projects" class="overflow-hidden mt-5">
    <v-row no-gutters>
      <v-col class="secondary pa-5" cols="12" md="6">
        <base-bubble-1 style="transform: rotate(180deg) translateX(25%)" />

        <base-heading class="mb-5 pa-2 white--text">
          Recent Projects
        </base-heading>
        <v-hover v-slot="{ hover }" v-for="proj in myProjects" :key="proj.title">
          <div class="mb-5 pa-3 white--text blue-grey darken-3 custom-cursor"
            :class="hover ? 'on-hover elevation-24' : 'elevation-0'" @click="renderContent(proj.content)">
            <div class="text-h6">{{ proj.title }}</div>
            <div>{{ proj.short_description }}</div>
          </div>
        </v-hover>
      </v-col>

      <v-col class="hidden-sm-and-down" md="6">
        <v-img :src="require('@/assets/recentprojects.png')" height="100%" />
      </v-col>
    </v-row>
    <full-project-description :project="project" :show="open" @openDesc="openDesc" />
  </section>
</template>

<script>
import * as sd from "showdown";
import projects from "@/assets/data/recentProjects.json";
export default {
  name: "RecentProjects",
  components: {
    FullProjectDescription: () => import("@/components/FullProjectDescription"),
  },
  data: () => ({
    myProjects: projects.projects,
    project: "",
    open: false,
  }),
  methods: {
    openDesc(val) {
      this.open = val;
    },
    renderContent(val) {
      this.open = true;
      import(`@/assets/data/content/${val}.md`).then((d) => {
        const converter = new sd.Converter();
        this.project = converter.makeHtml(d.default);
      });
    },
  },
};
</script>

<style>
.custom-list ul {
  margin: 15px;
  padding: 15px;
  list-style: none;
}

.custom-list ul li {
  margin-bottom: 25px;
  background-color: white;
}

.custom-list a {
  text-decoration: none;
  color: #424242;
}
</style>
